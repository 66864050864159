// utils
import DataService from 'src/utils/DataService';
// configs
import config from 'src/config';
import datasetConfig from 'ui/modules/Core/config/dataset.config';
import { data, getApiPrefix, server } from 'src/utils/api-prefix.helper';
// constants
const serverUrl = `${server}${getApiPrefix()}/`;

/**
 * update dataset
 * @param id
 * @param payload
 * @returns
 */
export function updateDatasetService(code: string, payload: any) {
	return new DataService({
		url: datasetConfig.url,
		urlParams: '',
	}).update(code, payload);
}

export function createDatasetService(payload: any) {
	return new DataService({
		url: datasetConfig.url,
		urlParams: '',
	}).create(payload);
}

export function cloneDatasetService(dataset_code: string, payload: any) {
	return new DataService({
		url: `${datasetConfig.url}/${dataset_code}/clone`,
		urlParams: '',
	}).create(payload);
}

// To be keep until the new service dataset can update the mapping
export function getOldDatasetService() {
	return new DataService({
		url: datasetConfig.oldUrl,
		urlParams: '?deep=true',
		token: true,
	});
}

// TODO: to be removed
export function getOldDatasetCreateService() {
	return new DataService({
		url: datasetConfig.oldUrl,
		urlParams: '?relation=[source,category,dataset_field]',
		token: true,
	});
}

export function getDatasetService(params: string | null = null) {
	return new DataService({
		url: `${datasetConfig.url}?${params || datasetConfig.urlParams}`,
		urlParams: '',
		token: true,
	});
}

export function getDatasetServiceByCode(dataset_code: string) {
	return new DataService({
		url: `${datasetConfig.url}/${dataset_code}?${datasetConfig.urlOneDatasetParams}`,
		urlParams: '',
	});
}

/**
 * delete dataset by code
 * @param id
 * @returns
 */
export function deleteDatasetServiceByCode(code: string) {
	return new DataService({
		url: datasetConfig.url,
		urlParams: '',
	}).delete(code);
}

export function getDataSharingServiceById(dataset_id: string) {
	return new DataService({
		url: `${config.api.data_sharing.url}/${dataset_id}?relation=[dataset]`,
		urlParams: '',
	});
}

export function getAllUsageService() {
	return new DataService({
		url: `${datasetConfig.url}/*/usage`,
	});
}

export function getDatasetUsageService(dataset_code: string) {
	return new DataService({
		url: `${datasetConfig.url}/${dataset_code}/usage`,
	});
}

export function getStatisticService(dataset_code: string) {
	return new DataService({
		url: `${config.api.ingestWorkflowUrl}/dataset/${dataset_code}/stats`,
		urlParams: '',
	});
}

export function onEmptyDatasetService(dataset_code: string) {
	return new DataService({
		url: `${serverUrl}${data}/datasets/${dataset_code}/empty`,
		urlParams: '',
	});
}

export function searchService(type: string) {
	return new DataService({
		url: config.api.searchUrl,
		urlParams: `/${type}?limit=10000`,
	});
}

export function deleteGlobalMappingService() {
	return new DataService({
		url: `${config.api.baseUrl}dataset_global_mapping`,
		urlParams: '',
	});
}

export function getGlobalValueService(params: string = '') {
	return new DataService({
		url: config.api.dataset_global_mapping.url,
		urlParams: params,
	});
}

export function UpdateDatasetFieldMappingIdService() {
	return new DataService({
		url: config.api.dataset_field.url,
		urlParams: '',
		token: true,
	});
}

export function getQueryDataWarehouseService() {
	return new DataService({
		url: `${config.api.clientUrl}/dataset-query`,
		urlParams: '',
	});
}

export function getDatasetShareService() {
	return new DataService({
		url: `${config.apiGateway.REST_URL}/dataset_share`,
		urlParams: '',
	});
}

/**
 * bulk delete dataset
 * @param payload
 * @returns
 */
export function bulkDeleteDatasetService(payload: any) {
	return new DataService({
		url: `${datasetConfig.url}/delete`,
		urlParams: '',
	}).create(payload);
}
