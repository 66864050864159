import config from 'src/config';
import DataService from 'src/utils/DataService';
import configDashboard from 'ui/modules/Dashboards/config/dashboard.config';
import { decodeToken } from 'ui/modules/Authentication/utils/authentication.helper';

export async function getAllDashboardsService(businessCategoryCode: string = '') {
	const endParams = businessCategoryCode ? `&business_category=${businessCategoryCode}` : '';
	const res = await new DataService({
		url: `${configDashboard.url}/?limit=10000${endParams}`,
		urlParams: '',
	}).getAll();

	const result = res?.data?.result || [];

	const sharedDashboardsMasterIds = new Set(result.filter((d: any) => d.shared).map((d: any) => d.master_id));

	// inject shared dashboards with the right category in the list
	try {
		const sharedElement = await getDataSettings();
		let sharedDashboards = sharedElement?.data?.settings || [];
		if (businessCategoryCode) {
			sharedDashboards = sharedDashboards.filter((d) => d.business_categories.includes(businessCategoryCode));
		}
		sharedDashboards = sharedDashboards
			// deduplicate shared dashboards which are already in the list with a master_id
			.filter((d: any) => !sharedDashboardsMasterIds.has(d.id))
			.map((d: any) => {
				return {
					...d,
					business_categories: d.business_categories.map((c) => {
						return {
							code: c,
						};
					}),
					shared: true,
				};
			});

		result.push(...sharedDashboards);
	} catch (err) {
		console.error('Error while fetching shared dashboards', err);
	}

	return res;
}

export function getDataService(noParams: boolean = false) {
	return new DataService({
		url: configDashboard.url,
		urlParams: noParams ? '' : '?limit=10000',
	});
}

export function getDashboardById(id: string) {
	return new DataService({
		url: `${configDashboard.url}/${id}`,
		urlParams: '',
	}).getAll();
}

// token cache
const tokenCache = {
	download: {
		token: '' as string,
		expiration: 0 as number,
		tokenPromise: null as Promise<string> | null,
	},
	noDownload: {
		token: '' as string,
		expiration: 0 as number,
		tokenPromise: null as Promise<string> | null,
	},
};

export function getDashboardToken(canBeDownloaded: boolean = false) {
	const cache = canBeDownloaded ? tokenCache.download : tokenCache.noDownload;
	const { token, expiration, tokenPromise } = cache;

	// call already in progress
	if (tokenPromise) {
		return tokenPromise;
	}

	// otherwise if cache, get it from cache
	const now = Date.now();
	if (token && expiration && expiration > now) {
		console.log(`Get new token from cache, will expire in ${expiration - now}ms`);
		return token;
	}

	cache.tokenPromise = new DataService({
		url: `${configDashboard.url}`,
	}).http
		.get('/token', {
			params: {
				download: canBeDownloaded,
			},
		})
		.then((result) => {
			const finalToken = result?.data?.result?.token;
			const decodedToken = decodeToken(finalToken);
			cache.token = finalToken;

			cache.expiration = decodedToken?.exp * 1000 - 10000;
			// Set maximum token expiration to 20 minutes
			const maxExpiration = now + 20 * 60 * 1000;
			cache.expiration = Math.min(cache.expiration, maxExpiration);
			cache.tokenPromise = null;

			return finalToken;
		});

	return cache.tokenPromise;
}

export async function getDataSettings() {
	return await new DataService({
		url: `${config.api.clientUrl}/sharing/active/dashboard`,
		urlParams: '',
	}).getAll();
}

export async function getDataSettingsDashboard(id: string) {
	return await new DataService({
		url: `${config.api.clientUrl}/sharing/settings/dashboard/${id}`,
		urlParams: '',
	}).getAll();
}
