export const validateMessages = {
	required: 'This field is required',
};

export const haveGroup = ['company', 'location', 'product', 'company_group', 'location_group', 'product_group'];

export const fieldRecord = {
	company: [
		'is_archived',
		'name',
		'code',
		'parent_id',
		'description',
		'hq_city',
		'hq_country',
		'corporate_website',
		'notes',
	],
	company_group: ['is_archived', 'name', 'code', 'parent_id', 'description', 'relations', 'is_group'],
	currency: ['is_archived', 'name', 'code'],
	custom_code: ['is_archived', 'name', 'parent_custom_code_id', 'code'],
	data_type: ['is_archived', 'name', 'code'],
	delivery_term: ['is_archived', 'name', 'code', 'description'],
	exchange: ['is_archived', 'name', 'code', 'website'],
	exchange_month_reference: ['is_archived', 'name', 'code'],
	location: ['is_archived', 'name', 'code', 'parent_id', 'level_name', 'description'],
	location_group: ['is_archived', 'name', 'code', 'parent_id', 'relations', 'is_group'],
	period_aggregation_type: ['is_archived', 'name', 'code'],
	vegetation_index_variable: ['is_archived', 'name', 'code'],
	cost_production_item: ['is_archived', 'name', 'code', 'parent_id', 'description'],
	price_type: ['is_archived', 'name', 'code'],
	product: ['is_archived', 'name', 'code', 'parent_id', 'relations'],
	product_group: ['is_archived', 'name', 'code', 'parent_id', 'relations', 'is_group'],
	product_category: ['is_archived', 'name', 'code'],
	quote_type: ['is_archived', 'name', 'code'],
	snd_item: ['is_archived', 'name', 'code', 'category'],
	trade_flow_mode: ['is_archived', 'name', 'code'],
	transport_category: ['is_archived', 'name', 'code'],
	transport_type: ['is_archived', 'name', 'code', 'parent_id', 'transport_category_id'],
	source: [
		'is_archived',
		'is_dnext_external',
		'code',
		'name',
		'parent_source_id',
		'address',
		'postal_code',
		'city',
		'state',
		'country_code',
		'phone',
		'email',
		'website',
		'business_activities',
		'contact_person',
		'source_type',
		'subscription_type',
		'copyright',
	],
	unit: ['is_archived', 'name', 'code', 'is_temporal', 'is_containing_temporal', 'is_relative'],
	vessel_status: ['is_archived', 'name', 'code'],
};

export const referencesWithRelations = [
	{ key: 'company_group', label: 'companies', record: 'company', title: 'Companies', path: 'company' },
	{ key: 'location_group', label: 'locations', record: 'location', title: 'Locations', path: 'locations' },
	{
		key: 'product',
		label: 'product_category',
		record: 'product_category',
		title: 'Product categories',
		path: 'product-categories',
	},
	{ key: 'product_group', label: 'products', record: 'product', title: 'Products', path: 'products' },
];

/**
 * References which requires a special role to be created
 * @type {string[]}
 */
export const restrictedReferences = ['location', 'product'];
