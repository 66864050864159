import React, { Component } from 'react';

import { Space, Dropdown, Menu } from 'antd';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
	faEye,
	faEdit,
	faTrash,
	faSpinner,
	faClone,
	faCogs,
	faCopy,
	faCodeBranch,
	faForward,
	faSync,
	faDownload,
	faChartLine,
} from '@fortawesome/pro-light-svg-icons';
import ActionButton from './Buttons/ActionButton';

// const dateNow = new Date(new Date().toLocaleString('en', { timeZone: 'Europe/Dublin' }));

export default class actionsRenderer extends Component {
	viewRecord() {
		if (this.props.viewRecord) return this.props.viewRecord(this.props);
	}

	refreshAndViewRecord() {
		if (this.props.refreshAndViewRecord) return this.props.refreshAndViewRecord(this.props);
	}

	editRecord() {
		if (this.props.editRecord) return this.props.editRecord(this.props);
	}

	executeWorkflow() {
		if (this.props.executeWorkflow) return this.props.executeWorkflow(this.props);
	}

	executeFreightRate() {
		if (this.props.executeFreightRate) return this.props.executeFreightRate(this.props);
	}

	executeLineupConsolidation() {
		if (this.props.executeLineupConsolidation) return this.props.executeLineupConsolidation(this.props);
	}

	executeDataPrep() {
		if (this.props.executeDataPrep) return this.props.executeDataPrep(this.props);
	}

	deleteRecord() {
		if (this.props.deleteRecord) return this.props.deleteRecord(this.props);
	}

	previewRecord() {
		if (this.props.previewRecord) return this.props.previewRecord(this.props);
	}

	cloneRecord() {
		if (this.props.cloneRecord) return this.props.cloneRecord(this.props);
	}

	duplicateRecord() {
		if (this.props.duplicateRecord) return this.props.duplicateRecord(this.props);
	}

	settingsRecord() {
		if (this.props.settingsRecord) return this.props.settingsRecord(this.props);
	}
	statsRecord() {
		if (this.props.statsRecord) return this.props.statsRecord(this.props);
	}
	downloadRecord() {
		if (this.props.downloadRecord) return this.props.downloadRecord(this.props);
	}
	refreshAndDownload() {
		if (this.props.refreshAndDownload) return this.props.refreshAndDownload(this.props);
	}

	render() {
		let diffTime = null;
		let locked = false;
		let userInfo = null;

		if (!this.props.data) return '';

		const isLockable = !!this.props.data?.is_used_by;
		if (isLockable) {
			let usersList = this.props.users || [];
			if (Array.isArray(usersList[0])) usersList = usersList[0];

			const dateUpdated_at = new Date(
				new Date(this.props.data.updatedAt).toLocaleString('en', { timeZone: 'Europe/Dublin' })
			);
			const dateNow = new Date(new Date().toLocaleString('en', { timeZone: 'Europe/Dublin' }));
			diffTime = Math.abs(dateNow - dateUpdated_at);

			let { is_used_by } = this.props.data;
			if (is_used_by) is_used_by = is_used_by.split('+')[0];
			const user = this.props.user?.user_id;

			if (is_used_by !== user && diffTime / 60000 < 1 && this.props.data.is_used_by != null) {
				userInfo = usersList.filter((f) => f.id === is_used_by)[0];

				locked = true;
			}
		}

		const isRunnable =
			(this.props.data.state === 'stopped' ||
				this.props.data.trigger_type === 'none' ||
				(this.props.data.last_status !== 'success' && this.props.data.last_status !== 'error')) &&
			this.props.data.type !== 'transform';

		const actions = {
			canRefreshAndView: {
				action: 'canRefreshAndView',
				callback: this.refreshAndViewRecord.bind(this),
				icon: (
					<div className="fa-layers fa-fw">
						<FontAwesomeIcon icon={faSync} style={{ color: '#bbbbbb' }} transform="up-2 left-3" />
						<FontAwesomeIcon icon={faEye} transform="shrink-3 down-4 right-4" />
					</div>
				),
				specialicon: true,
				disabled: false,
			},
			canDownload: {
				action: 'canDownload',
				callback: this.downloadRecord.bind(this),
				icon: faDownload,
				specialicon: false,
				disabled: this.props.data.can_be_downloaded === false,
			},
			canRefreshAndDownload: {
				action: 'canRefreshAndDownload',
				callback: this.refreshAndDownload.bind(this),
				icon: (
					<div className="fa-layers fa-fw">
						<FontAwesomeIcon icon={faSync} style={{ color: '#bbbbbb' }} transform="up-2 left-3" />
						<FontAwesomeIcon icon={faDownload} transform="shrink-3 down-4 right-4" />
					</div>
				),
				specialicon: true,
				disabled: this.props.data.can_be_downloaded === false,
			},
			visualize: {
				action: 'visualize',
				callback: this.viewRecord.bind(this),
				icon: faEye,
				specialicon: false,
				disabled: false,
			},
			delete: {
				action: !this.props.data.shared ? 'delete' : 'cannot_delete',
				callback: this.deleteRecord.bind(this),
				icon: faTrash,
				specialicon: false,
				disabled: this.props.data.shared,
			},
			preview: {
				action: 'preview',
				callback: this.previewRecord.bind(this),
				icon: faEye,
				specialicon: false,
				disabled: false,
			},
			canbecloned: {
				action: this.props.data.global !== '1' && !this.props.data.shared ? 'clone' : 'cannot_clone',
				callback: this.cloneRecord.bind(this),
				icon: faClone,
				specialicon: false,
				disabled: this.props.data.global === '1' || this.props.data.shared,
			},
			canbeDuplicate: {
				action: !this.props.data.shared ? 'duplicate' : 'fork',
				callback: this.duplicateRecord.bind(this),
				icon: this.props.data.shared ? faCodeBranch : faCopy,
				specialicon: false,
				disabled: false,
			},
			canbeSettings: {
				action: this.props.data.global !== '1' ? 'priority_rule' : 'not_access',
				callback: this.settingsRecord.bind(this),
				icon: faCogs,
				specialicon: false,
				disabled: this.props.data.global === '1' || this.props.data.shared || this.props.data.aggregated,
			},
			canSeeStats: {
				action: 'stats',
				callback: this.statsRecord.bind(this),
				icon: faChartLine,
				specialicon: false,
				disabled: this.props.data.global === '1' || this.props.data.shared,
			},
			edit: {
				action: !locked
					? this.props.data.global !== '1' && !this.props.data.shared
						? 'edit'
						: 'cannot_edit'
					: 'is_using_workflow',
				callback: this.editRecord.bind(this),
				prefix: locked && (userInfo ? userInfo.email : 'Someone'),
				icon: faEdit,
				specialicon: false,
				disabled: this.props.data.global === '1' || this.props.data.shared || locked,
			},
			execute: {
				action: !isRunnable ? 'execute_workflow' : 'cannot_execute_workflow',
				prefix: locked && (userInfo ? userInfo.email : 'Someone'),

				callback: this.executeWorkflow.bind(this),
				icon: (
					<FontAwesomeIcon
						spin={this.props.data.last_status === 'waiting'}
						icon={
							this.props.data.last_status === 'waiting' || this.props.data.last_status === 'running'
								? faSpinner
								: faForward
						}
						transform="grow-5"
					/>
				),
				specialicon: true,
				disabled: isRunnable,
				style: { height: '30px', width: '30px' },
			},
			execute_data_prep: {
				//action: !isRunnable ? "execute_data_prep" : "cannot_execute_data_prep",
				action: 'execute_data_prep',
				prefix: locked && (userInfo ? userInfo.email : 'Someone'),

				callback: this.executeDataPrep.bind(this),
				icon: (
					<FontAwesomeIcon
						spin={this.props.data.last_status === 'waiting'}
						icon={
							this.props.data.last_status === 'waiting' || this.props.data.last_status === 'running'
								? faSpinner
								: faForward
						}
						transform="grow-5"
					/>
				),
				specialicon: true,
				//disabled: isRunnable,
				style: { height: '30px', width: '30px' },
			},

			execute_freight_rate: {
				action: 'execute_freight_rate',
				prefix: locked && (userInfo ? userInfo.email : 'Someone'),

				callback: this.executeFreightRate.bind(this),
				icon: (
					<FontAwesomeIcon
						spin={this.props.data.last_status === 'waiting'}
						icon={
							<FontAwesomeIcon
								spin={this.props.data.last_status === 'waiting'}
								icon={
									this.props.data.last_status === 'waiting' ||
									this.props.data.last_status === 'running'
										? faSpinner
										: faForward
								}
								transform="grow-5"
							/>
						}
						transform="grow-5"
					/>
				),
				specialicon: true,
				disabled: isRunnable,
				style: { height: '30px', width: '30px' },
			},

			execute_lineup_consolidation: {
				action: 'execute_lineup_consolidation',
				prefix: locked && (userInfo ? userInfo.email : 'Someone'),

				callback: this.executeLineupConsolidation.bind(this),
				icon: <FontAwesomeIcon spin={false} icon={faForward} transform="grow-5" />,
				specialicon: true,
				disabled: true,
				style: { height: '30px', width: '30px' },
			},
		};

		const listBtns = this.props.listButtons || [];

		return (
			<Space size="small">
				{this.props.canRefreshAndView && !listBtns.includes('canRefreshAndView') && (
					<ActionButton {...actions.canRefreshAndView} {...this.props} />
				)}

				{this.props.visualize && !listBtns.includes('visualize') && (
					<ActionButton {...actions.visualize} {...this.props} />
				)}

				{this.props.edit && !listBtns.includes('edit') && <ActionButton {...actions.edit} {...this.props} />}

				{this.props.canbecloned && !listBtns.includes('canbecloned') && (
					<ActionButton {...actions.canbecloned} {...this.props} />
				)}

				{this.props.canbeDuplicate && !listBtns.includes('canbeDuplicate') && (
					<ActionButton {...actions.canbeDuplicate} {...this.props} />
				)}

				{this.props.canbeSettings && !listBtns.includes('canbeSettings') && (
					<ActionButton {...actions.canbeSettings} {...this.props} />
				)}

				{this.props.execute && !listBtns.includes('execute') && (
					<ActionButton {...actions.execute} {...this.props} />
				)}

				{this.props.execute_data_prep && !listBtns.includes('execute_data_prep') && (
					<ActionButton {...actions.execute_data_prep} {...this.props} />
				)}

				{this.props.execute_freight_rate && !listBtns.includes('execute_freight_rate') && (
					<ActionButton {...actions.execute_freight_rate} {...this.props} />
				)}

				{this.props.execute_lineup_consolidation && !listBtns.includes('execute_lineup_consolidation') && (
					<ActionButton {...actions.execute_lineup_consolidation} {...this.props} />
				)}

				{this.props.delete && !listBtns.includes('delete') && (
					<ActionButton {...actions.delete} {...this.props} />
				)}

				{this.props.preview && !listBtns.includes('preview') && (
					<ActionButton {...actions.preview} {...this.props} />
				)}

				{listBtns.length > 0 && (
					<Dropdown.Button
						className="no-btn list_action"
						overlay={
							<Menu
								onClick={(val) => null}
								items={listBtns.map((c) => ({
									label: (
										<>
											<span style={{ width: '20px', display: 'inline-block' }}>
												{actions[c]?.specialicon ? (
													actions[c]?.icon
												) : (
													<FontAwesomeIcon icon={actions[c]?.icon} />
												)}
											</span>
											<span className="ml-2">
												{actions[c]?.prefix ? actions[c]?.prefix : ''}
												{this.props.t(`actions.${actions[c]?.action}`)}
											</span>
										</>
									),
									key: c,
									onClick: actions[c]?.callback,
									disabled: actions[c]?.disabled,
								}))}
							/>
						}
					>
						e
					</Dropdown.Button>
				)}
			</Space>
		);
	}
}
