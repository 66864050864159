// @ts-nocheck
import React, { Component } from 'react';
import PropTypes from 'prop-types';
// ag-grid imports &  custom params ag-grid
import { AgGridReact } from 'ag-grid-react';
import * as Filters from './Filters/Filters';
import { AllModules } from 'ag-grid-enterprise';
import DefaultColDef from './Filters/DefaultColDef';
import SimpleFilters from './Filters/SimpleFilters';
import * as CellRenderers from './CellRenderers/CellRenderers';
import * as CustomComponents from './CustomComponents/CustomComponents';
// react-bootstrap & antd & icons
import { Row, Col } from 'react-bootstrap';
import { SearchOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Input, Select, Button, Tooltip, Switch, Space } from 'antd';
import { faCheck, faFilter, faInfoCircle, faSyncAlt, faTimes, faTimesCircle } from '@fortawesome/pro-light-svg-icons';
// components
import Loader from 'ui/components/Loader/Loader';
import FloatLabel from 'ui/components/Form/FloatLabel';
import PromptModal from 'ui/components/Modal/PromptModal';
import LoaderOverlay from '../LoaderOverlay/LoaderOverlay';
import BulkExecuteModal from '../BulkExecuteModal/BulkExecuteModal';
import DeleteUserModal from 'ui/modules/Core/components/Users/DeleteUserModal/DeleteUserModal';
// custom hooks & context
import userContext from 'libs/contextLib';
import ConfirmDialog from 'src/utils/ConfirmDialog';
import { pushNotification } from 'src/utils/PushNotification';
// shared services
import DataService from 'utils/DataService';
import { downloadTradeflow } from 'ui/modules/Fundamentals/Tradeflows/services/tradeflow.service';
import { run } from 'ui/modules/Pipeline/utils/service.dataprep';
import { applyTagsByResources } from 'ui/modules/Core/services/label.service';
import { getSplittedWorkflow } from 'ui/modules/Core/services/workflow.service';
import { closeNotebook } from 'src/ui/modules/Reporting/services/notebook.service';
import { bulkDeleteDatasetService } from 'src/ui/modules/Core/services/dataset.service';
import { bulkDeleteCategoryService } from 'src/ui/modules/Core/services/category.service';
import { scheduleWorkflowImmediately } from 'src/ui/modules/Core/services/workflow.service';
import {
	bulkDeleteReferencesService,
	deleteReferenceByCode,
	getReferenceDataByType,
	updateReference,
} from 'ui/modules/References/services/reference.service';
// shared helpers
import _, { flatten, get, isArray, isEmpty, isNil, uniq } from 'lodash';
import moment from 'moment';
import { data, fundamentals } from 'src/utils/api-prefix.helper';
import {
	getRelatedReferencesData,
	matchReferenceList,
	refreshContextListReference,
	contextOrAPIGetReference,
} from 'src/ui/modules/References/utils/reference.helper';
import { getLabels } from 'ui/modules/Core/utils/label.helper';
import { centerCellStyle } from 'src/utils/AGGridFilters/FilterParams';
// shared configs
import config from 'src/config';
// style & css
import 'ag-grid-community/dist/styles/ag-grid.min.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.min.css';
// import "ag-grid-community/dist/styles/ag-theme-balham-dark.css"; // test dark mode
import './list.sass';

const { searchUrl } = config.api;
const { baseUrl } = config.api;
const { clientUrl } = config.api;
const { Option } = Select;

class List extends Component {
	static contextType = userContext;

	static defaultProps = {
		showCheckBox: true,
		showLock: false,
		showSearchBox: true,
		showPagination: false,
		showRowsPerPageControl: true,
		adjustHeightToContent: false,
		actions: {
			visualize: true,
			edit: true,
			delete: true,
			execute: true,
		},
		defaultColDef: DefaultColDef,
	};

	static propTypes = {
		columns: PropTypes.array.isRequired,
		dataService: PropTypes.object.isRequired,
		data: PropTypes.object,
		path: PropTypes.string,
		defaultColDef: PropTypes.object,
		defaultSortModel: PropTypes.array,

		showLock: PropTypes.bool,
		showCheckBox: PropTypes.bool,
		showSearchBox: PropTypes.bool,
		showPagination: PropTypes.bool,
		showRowsPerPageControl: PropTypes.bool,
		adjustHeightToContent: PropTypes.bool,
		actions: PropTypes.shape({
			showVisualize: PropTypes.bool,
			showEdit: PropTypes.bool,
			showDelete: PropTypes.bool,
			showExecute: PropTypes.bool,
			showCanLock: PropTypes.bool,
			showCanbeCloned: PropTypes.bool,
			showcanbeDuplicate: PropTypes.bool,
			showCanbeSettings: PropTypes.bool,
			showCanbeRefreshAndView: PropTypes.bool,
			showCanbeDownload: PropTypes.bool,
			showCanbeRefreshAndDownload: PropTypes.bool,
		}),
	};

	constructor(props) {
		super(props);

		this.columnTypes = {
			boolean: {
				cellRenderer: 'booleanRenderer',
				filter: 'agSetColumnFilter',
				filterParams: {
					values: ['true', 'false'],
					applyMiniFilterWhileTyping: true,
					cellRenderer: 'booleanRenderer',
					buttons: ['reset'],
				},
				valueGetter: (params) => {
					const value = _.get(params.data, params.colDef.field);
					return value === true || value === 1 ? true : false;
				},
				menuTabs: ['generalMenuTab'],
			},
			phone: { cellRenderer: 'phoneRenderer', menuTabs: ['generalMenuTab'] },
			email: { cellRenderer: 'emailRenderer', menuTabs: ['generalMenuTab'] },
			date: {
				cellRenderer: 'dateCellRenderer',
				filter: 'agDateColumnFilter',
				filterParams: {
					browserDatePicker: true,
					comparator: function (filterLocalDateAtMidnight, cellValue) {
						// Ensure the cell value is interpreted as a date only, without time
						var cellDate = moment(cellValue, 'YYYY-MM-DD').startOf('day').toDate();
						if (cellDate < filterLocalDateAtMidnight) {
							return -1;
						} else if (cellDate > filterLocalDateAtMidnight) {
							return 1;
						} else {
							return 0;
						}
					},
					buttons: ['reset'],
					filterOptions: [
						'equals',
						'notEqual',
						'greaterThan',
						'lessThan',
						SimpleFilters.isEmpty,
						SimpleFilters.isNotEmpty,
						'inRange',
					],
				},
				menuTabs: ['generalMenuTab'],
			},
			datetime: {
				cellRenderer: 'datetimeCellRenderer',
				filter: 'agDateColumnFilter',
				filterParams: {
					browserDatePicker: true,
					comparator: function (filterLocalDateAtMidnight, cellValue) {
						// Ensure the cell value is interpreted as a date only, without time
						var cellDate = moment(cellValue, 'YYYY-MM-DD').startOf('day').toDate();
						if (cellDate < filterLocalDateAtMidnight) {
							return -1;
						} else if (cellDate > filterLocalDateAtMidnight) {
							return 1;
						} else {
							return 0;
						}
					},
					buttons: ['reset'],
					filterOptions: [
						'equals',
						'notEqual',
						'greaterThan',
						'lessThan',
						SimpleFilters.isEmpty,
						SimpleFilters.isNotEmpty,
						'inRange',
					],
				},
				menuTabs: ['generalMenuTab'],
			},
			url: { cellRenderer: 'urlRenderer', menuTabs: ['generalMenuTab'] },
			tag: {
				cellRenderer: 'tagRenderer',
				filter: 'agSetColumnFilter',
				filterParams: {
					values: (params) => {
						params.success(params.colDef.values);
					},
					suppressSorting: true,
					buttons: ['reset'],
					applyMiniFilterWhileTyping: true,
					cellRenderer: 'tagRenderer',
				},
				menuTabs: ['generalMenuTab'],
			},
			label: {
				cellRenderer: 'tagRenderer',
				filterParams: {
					cellRendererParams: {
						mapping: {
							...config.labels.system,
							...config.labels[this.props.recordType === 'workflow' ? 'dataset' : this.props.recordType],
						},
					},
				},
				cellRendererParams: {
					mapping: {
						...config.labels.system,
						...config.labels[this.props.recordType === 'workflow' ? 'dataset' : this.props.recordType],
					},
				},
				menuTabs: ['generalMenuTab'],
				minWidth: 200,
			},
			labels: {
				cellRendererFramework: getLabels,
				cellStyle: centerCellStyle,
				filter: 'agSetColumnFilter',
				filterParams: {
					values: (params) => {
						const { field } = params.colDef;
						const allUniqueLabels = uniq(
							flatten(
								(this.state.rowData || []).map((node) =>
									(get(node, field) || []).map((label) => label.name)
								)
							)
						);
						params.success(allUniqueLabels);
					},
					buttons: ['reset'],
					applyMiniFilterWhileTyping: true,
					cellRenderer: 'labelRenderer',
				},
				filterValueGetter: (params) => {
					const { field } = params.colDef;
					const labels = get(params.data, field) || [];
					return labels.map((el) => el.name);
				},
			},
			stateWorkflow: {
				cellRenderer: 'stateRenderer',
				filter: 'agSetColumnFilter',
				cellStyle: (params) => ({ 'line-height': '25px' }),
				filterParams: {
					values: (params) => {
						params.success(params.colDef.values);
					},
					cellRenderer: (params) => {
						if (!params.value || params.value === '(Select All)') {
							return params.value;
						}
						return params.value;
					},
				},
				menuTabs: ['generalMenuTab'],
			},
			reference: {
				cellRenderer: 'referenceRenderer',
				valueGetter(params) {
					const referencedValue = _.get(params.data, params.colDef.field);
					if (referencedValue) {
						if (params.colDef.referenceType === 'user') {
							return `${referencedValue.first_name} ${referencedValue.last_name}`;
						}
						return referencedValue.name;
					}
				},
				menuTabs: ['generalMenuTab'],
			},
			mergeEntity: {
				valueGetter(params) {
					return `${params.data.group} / ${params.data.subgroup} / ${params.data.name}`;
				},
				menuTabs: ['generalMenuTab'],
			},
			referenceList: {
				cellRenderer: 'referenceListRenderer',
				valueGetter(params) {
					if (!params.data) {
						return params.node.key || 'Empty';
					}
					const list = params.data[params.colDef.field];
					if (isNil(list) || isEmpty(list) || !isArray(list)) return '';

					let value = '';
					for (let i = 0; i < list.length; i++) value += (i > 0 ? ',' : '') + list[i].name;
					return value;
				},
				filterParams: {
					filterOptions: ['contains', 'notContains', SimpleFilters.isEmpty, SimpleFilters.isNotEmpty],
					buttons: ['reset'],
				},
				menuTabs: ['generalMenuTab'],
			},
			firstEntryFromList: {
				cellRenderer: 'firstEntryFromListRenderer',
				valueGetter(params) {
					if (!params.data) return '';
					const list = params.data[params.colDef.field];
					const { field_in_list } = params.colDef;

					if (!list || !list[0]) return null;

					const value = list[0][field_in_list] || null;
					return value;
				},
				filterParams: {
					filterOptions: ['contains', 'notContains', SimpleFilters.isEmpty, SimpleFilters.isNotEmpty],
					buttons: ['reset'],
				},
				menuTabs: ['generalMenuTab'],
			},
			executionPath: {
				cellRenderer: 'workflowExecutionPathRenderer',
				menuTabs: [],
			},
			showlock: {
				cellRenderer: 'showLockRenderer',
				minWidth: 0,
				menuTabs: [],
				sortable: false,
				resizable: false,
				suppressSizeToFit: true,
				filter: false,
				lockVisible: true,
				lockPosition: true,
				lockPinned: true,
				suppressColumnsToolPanel: true,
				suppressFiltersToolPanel: true,
				user: this.props.user,
			},
			referenceCode: {
				cellRenderer: 'tagRenderer',
				valueGetter(params) {
					if (!params.data) return '';
					const list = params.data[params.colDef.field];

					let value = '';
					if (Array.isArray(list)) {
						for (let i = 0; i < list.length; i++) value += (i > 0 ? ', ' : '') + list[i].code;
					} else {
						const referencedValue = _.get(params.data, params.colDef.field);
						if (referencedValue) value = referencedValue.code;
					}

					return value;
				},
				filterParams: {
					filterOptions: ['contains', 'notContains', SimpleFilters.isEmpty, SimpleFilters.isNotEmpty],
					buttons: ['reset'],
				},
				menuTabs: ['generalMenuTab'],
			},
			aliases: {
				cellRenderer: 'tagRenderer',
				valueGetter(params) {
					if (!params.data) return '';
					const list = params.data[params.colDef.field];

					let value = '';
					for (let i = 0; i < list.length; i++) value += (i > 0 ? ',' : '') + list[i].name;
					return value;
				},
				filterParams: {
					filterOptions: ['contains', 'notContains', SimpleFilters.isEmpty, SimpleFilters.isNotEmpty],
					buttons: ['reset'],
				},
				menuTabs: ['generalMenuTab'],
			},
			currency: {},
			pinned: {
				minWidth: 0,
				menuTabs: [],
				sortable: false,
				resizable: false,
				suppressSizeToFit: true,
				filter: false,
				lockVisible: true,
				lockPosition: true,
				lockPinned: true,
				suppressColumnsToolPanel: true,
				suppressFiltersToolPanel: true,
			},

			bulk_execution: null,
		};

		/* We use the configRecords to send parameters to the cell renderers. We need to do it like this, because otherwise we can't use filterParams in the type definition */
		const columnsWithTypeParameters = _.filter(this.props.columns, 'typeParams');
		columnsWithTypeParameters.map((column) => {
			column.cellRendererParams = column.typeParams;
			const columnType = this.columnTypes[column.type];
			column.filterParams = {
				...columnType,
				cellRendererParams: column.typeParams,
			};
		});

		this.cols = this.props.columns || [];
		this.org = null;

		// We add the checkbox column if the option has been selected
		if (this.props.showCheckBox) {
			this.cols = this.cols.concat({
				colId: 'checkbox',
				pinned: 'left',
				maxWidth: 40,
				checkboxSelection: true,
				headerCheckboxSelection: true,
				headerCheckboxSelectionFilteredOnly: true,
				type: 'pinned',
			});
		}

		this.state = {
			columns: this.cols,
			defaultColDef: this.props.defaultColDef,
			canFilter: this.props.canFilter || [],
			isShowHierarchy:
				this.props.recordType === 'category' ||
				this.props.recordType === 'source' ||
				this.props.isShowHierarchy,
			currentExecution: [],
			axKey: 0,
			autoGroupColumnDef: {
				headerName:
					this.props.recordType === 'category' ||
					this.props.recordType === 'source' ||
					this.props.isShowHierarchy
						? 'Name'
						: null,
				cellRendererParams: {
					suppressCount: true,
				},
				minWidth: 200,
			},
			filteredElement: 0,

			frameworkComponents: {
				// CellRenderers
				booleanRenderer: CellRenderers.booleanRenderer,
				emailRenderer: CellRenderers.emailRenderer,
				phoneRenderer: CellRenderers.phoneRenderer,
				actionsRenderer: CellRenderers.actionsRenderer,
				dateCellRenderer: CellRenderers.dateCellRenderer,
				datetimeCellRenderer: CellRenderers.datetimeCellRenderer,
				detailRenderer: CellRenderers.detailRenderer,
				urlRenderer: CellRenderers.urlRenderer,
				tagRenderer: CellRenderers.tagRenderer,
				labelRenderer: CellRenderers.labelRenderer,
				listRenderer: CellRenderers.listRenderer,
				stateRenderer: CellRenderers.stateRenderer,
				referenceRenderer: CellRenderers.referenceRenderer,
				referenceListRenderer: CellRenderers.referenceListRenderer,
				firstEntryFromListRenderer: CellRenderers.firstEntryFromListRenderer,
				workflowExecutionPathRenderer: CellRenderers.workflowExecutionPathRenderer,
				showLockRenderer: CellRenderers.showLockRenderer,
				AutoCompleteFilter: Filters.AutoCompleteFilter,
				agDateInput: CustomComponents.CustomDateComponent,
			},
			detailCellRenderer: 'detailRenderer',
			rowSelection: 'multiple',
			suppressRowClickSelection: true,
			rowGroupPanelShow: 'onlyWhenGrouping',
			cacheQuickFilter: true,
			rowData: this.props.data || null,
			ready: false,

			confirmProps: {
				confirmFunction: null,
				confirmtext: '',
			},
			gridReady: false,

			filterTextBox: '',
			isOpenDeleteModal: false,
			isLoadingOverlay: false,
			selectedUsers: null,
		};

		this.user = null;
		this.pressed = false;

		// Override receive notification handler if needed
		this.receiveNotification = this.props.onReceiveNotification
			? this.props.onReceiveNotification.bind(this)
			: this.receiveNotification.bind(this);
	}

	async componentDidMount() {
		const { context } = this;
		this.org = context.user.org || null;
		this.user = context.user || null;
		let users = [];

		window.addEventListener('keydown', this.downHandler.bind(this));
		window.addEventListener('keyup', this.upHandler.bind(this));
		if (this.props.getReferenceListValues && this.props.configList.getListUser) {
			users = await this.getRecordType('user');
		}
		// Allows to receive any notification related to this workflow
		if (this.props.recordType === 'workflow' || this.props.recordType === 'transform_workflow') {
			this.notificationSubscription = this.props.subscribeToType('workflow', this.receiveNotification);
		}
		if (this.props.recordType === 'trade_flow') {
			this.notificationSubscription = this.props.subscribeToType('tradeflow', this.receiveNotification);
		}
		if (this.props.recordType === 'pipeline') {
			this.notificationSubscription = this.props.subscribeToType('dataprep', this.receiveNotification);
		}

		const numberOfActions =
			(this.props.actions.visualize ? 1 : 0) +
			// (this.props.actions.canlock ? 1 : 0);
			(this.props.actions.edit ? 1 : 0) +
			(this.props.actions.delete ? 1 : 0) +
			(this.props.actions.canbecloned ? 1 : 0) +
			(this.props.actions.canbeDuplicate ? 1 : 0) +
			(this.props.actions.canRefreshAndView ? 1 : 0) +
			(this.props.actions.canDownload ? 1 : 0) +
			(this.props.actions.canSeeStats ? 1 : 0) +
			(this.props.actions.canRefreshAndDownload ? 1 : 0) +
			(this.props.actions.canbeSettings ? 1 : 0) +
			(this.props.actions.execute ? 1 : 0) +
			(this.props.actions.execute_data_prep ? 1 : 0) +
			(this.props.actions.execute_freight_rate ? 1 : 0) +
			(this.props.actions.execute_lineup_consolidation ? 1 : 0) -
			this.props.listButtons.length +
			(this.props.listButtons.length > 0 ? 2 : 0);

		// If there are any actions selected, we show the Actions column
		if (this.props.actions && numberOfActions > 0) {
			this.cols = this.cols.concat({
				headerName: '',
				colId: 'actions',
				pinned: 'right',
				maxWidth: 12 * 2 + numberOfActions * 30 + (numberOfActions - 1) * 8,
				width: 12 * 2 + numberOfActions * 30 + (numberOfActions - 1) * 8,
				minWidth: 12 * 2 + numberOfActions * 30 + (numberOfActions - 1) * 8,
				type: 'pinned',
				cellRenderer: 'actionsRenderer',
				cellRendererParams: {
					visualize: this.props.actions.visualize,
					edit: this.props.actions.edit,
					delete: this.props.actions.delete,
					execute: this.props.actions.execute,
					execute_freight_rate: this.props.actions.execute_freight_rate,
					execute_lineup_consolidation: this.props.actions.execute_lineup_consolidation,
					execute_data_prep: this.props.actions.execute_data_prep,
					canlock: this.props.actions.canlock,
					canbecloned: this.props.actions.canbecloned,
					canbeDuplicate: this.props.actions.canbeDuplicate,
					canRefreshAndView: this.props.actions.canRefreshAndView,
					canDownload: this.props.actions.canDownload,
					canRefreshAndDownload: this.props.actions.canRefreshAndDownload,
					listButtons: this.props.listButtons,
					canbeSettings: this.props.actions.canbeSettings,
					executeWorkflow: this.executeWorkflow.bind(this),
					executeFreightRate: this.executeFreightRate.bind(this),
					executeLineupConsolidation: this.executeLineupConsolidation.bind(this),
					executeDataPrep: this.executeDataPrep.bind(this),
					editRecord: this.openEditRecord.bind(this),
					cloneRecord: this.openCloneRecord.bind(this),
					duplicateRecord: this.duplicateRecord.bind(this),
					settingsRecord: this.openSettingsRecord.bind(this),
					statsRecord: this.openStatsRecord.bind(this),
					viewRecord: this.openViewRecord.bind(this),
					refreshAndViewRecord: this.refreshAndViewRecord.bind(this),
					refreshAndDownload: this.refreshAndDownload.bind(this),
					downloadRecord: this.popConfirmDownload.bind(this),
					deleteRecord:
						this.props.recordType === 'user'
							? this.onOpenDeleteUserModal.bind(this)
							: this.askConfirmationBeforeDelete.bind(this, this._deleteOneRecord.bind(this)),
					user: this.user,
					users,
					t: this.props.t,
				},
			});
		}

		// map descriptions of column's header
		this.cols = this.cols.map((col) => {
			if (col.description) {
				return {
					...col,
					headerComponentFramework: (params) => this.CustomHeaderComponent(params, col.description), // Custom header component
				};
			}
			return col;
		});

		this.setState({ columns: this.cols, ready: true });
	}

	CustomHeaderComponent(params, content) {
		const { column, api } = params;
		// Function to handle click event and trigger sorting
		const handleSort = () => {
			const currentSort = column.getSort();
			let sort = 'asc';
			if (currentSort === 'asc') {
				sort = 'desc';
			} else if (currentSort === 'desc') {
				sort = null;
			}
			api.setSortModel([{ colId: column.getColId(), sort }]);
		};
		return (
			<Space className="w-100 d-flex justify-content-between" onClick={handleSort}>
				<span>{column.getColDef().headerName}</span>
				<Tooltip
					title={() => <div className="ag-grid-tooltip">{content}</div>}
					placement="top"
					mouseLeaveDelay={0}
					overlayStyle={{ maxWidth: '300px' }}
					color="white"
				>
					<FontAwesomeIcon style={{ cursor: 'pointer' }} icon={faInfoCircle} />
				</Tooltip>
			</Space>
		);
	}

	downHandler({ key }) {
		if (key === 'Control') {
			this.pressed = true;
		}
	}

	upHandler({ key }) {
		if (key === 'Control') {
			this.pressed = false;
		}
	}

	async getRecordType(recordType) {
		let users = [];

		if (!this.props.isReferenceListLoaded(recordType)) {
			users = await new DataService({
				url: config.api[recordType].url,
				urlParams: "?exclude=[parent]&limit=10000&order=[['name', 'ASC']]",
			}).getAll();
			this.props.setReferenceListValues(recordType, users);
		} else {
			users = this.props.getReferenceListValues(recordType);
		}

		return this.props.getReferenceListValues(recordType);
	}

	// Unsubscribe when not needed anymore
	componentWillUnmount() {
		window.removeEventListener('keydown', this.downHandler);
		window.removeEventListener('keyup', this.upHandler);
		if (typeof this.props.unsubscribeFromType === 'function') {
			this.props.unsubscribeFromType('workflow', this.notificationSubscription);
			this.props.unsubscribeFromType('tradeflow', this.notificationSubscription);
			this.props.unsubscribeFromType('dataprep', this.notificationSubscription);
		}
	}

	// Notification handler
	async receiveNotification(event) {
		if (event.object_type !== 'tradeflow') {
			pushNotification(
				this.props.addToast,
				this.props.t(`model.workflow.${event.type}.${event.code}`),
				event.type,
				event.type === 'success' ? null : event.details
			);
		}
	}

	_getRowData() {
		return this.state.rowData;
	}

	formatDataFromRecordType(rowData) {
		return this.props.configList.formatDataFromList(rowData);
	}

	_updateData(data) {
		let rowData = data;

		rowData.map((d) => {
			if (d.hasOwnProperty('is_enabled')) {
				d.is_enabled = Boolean(d.is_enabled);
			}
			return d;
		});

		if (this.props.configList.formatDataFromList) {
			rowData = this.formatDataFromRecordType(rowData);
		}

		if (this.props.configList.translations) {
			rowData = this.props.configList.translations(rowData, this.props.t);
		}

		if (this.state.isShowHierarchy) {
			rowData = _.orderBy(rowData, ['name'], ['asc']);
			rowData = rowData.map((d) => {
				d.hierarchy = [];
				d.hierarchy.push(d.name);

				let { parent } = d;
				while (parent) {
					d.hierarchy.unshift(parent.name);
					const parentFound = data.find((p) => p.id === parent.id);
					parent = parentFound ? parentFound.parent : null;
				}

				return d;
			});
		}
		this.setState({ rowData, filteredElement: this.gridApi?.getDisplayedRowCount() || rowData.length });
		// We need to restore the filter again for filters of type set, for which we don't know the possible values
		const workflow_code = this.getWorkflowCode();
		if (workflow_code) {
			this.gridApi.setFilterModel({
				'dataset.code': {
					filterType: 'text',
					type: 'contains',
					filter: workflow_code,
				},
			});
		} else this._restoreState();
	}

	_alterRowData(id, newRowData, newStatus, filteredByEntity = false) {
		if (!this.gridApi) return;
		let rowNode = this.gridApi.getRowNode(id);

		if (filteredByEntity && !rowNode) {
			this.gridApi.forEachNode((node) => {
				if (node.data[filteredByEntity] === id) {
					rowNode = node;
				}
			});
		}

		if (newStatus) {
			for (let i = 0; i < this.state.currentExecution.length; i++) {
				rowNode = this.gridApi.getRowNode(this.state.currentExecution[i]);
				if (!rowNode) continue;
				rowNode.setData({ ...rowNode.data, ...newRowData });
			}
			return;
		}

		if (!rowNode) return;

		rowNode.setData({ ...rowNode.data, ...newRowData });

		this.gridApi.refreshClientSideRowModel();
	}

	getWorkflowCode() {
		const withParamsCodeWorkflow = _.includes(window.location.search, 'workflow_code');
		if (withParamsCodeWorkflow) {
			const workflow_code = _.replace(window.location.search, '?workflow_code=', '');
			return workflow_code;
		}
		return null;
	}

	_onGridReady(params) {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;
		if (!this.props.data) {
			if (this.props.dataService) {
				this._retrieveData();
			} else {
				this._updateData(this.props.data);
			}
		}

		// Here we expose internal methods to parent classes
		if (this.props.onListReady) {
			this.props.onListReady({
				deleteSelectedRecords:
					this.props.recordType === 'user'
						? this.onOpenDeleteUserModal.bind(this)
						: this.askConfirmationBeforeDelete.bind(this, this._deleteSelectedRecords.bind(this)),
				toggleActiveRecords: this.toggleActiveRecords.bind(this),
				toggleStateWorkflows: this.toggleStateWorkflows.bind(this),
				ApplyTagsEntity: this.ApplyTagsEntity.bind(this),
				moveParentSelectedRecords: this.moveParentSelectedRecords.bind(this),
				getSelectedRecords: this.getSelectedRecords.bind(this),

				showBulkExecuteModal: () =>
					this.setState({
						bulk_execution: {
							bulk_execution_model: this.props.configList.bulk_execution_model,
							records: this.gridApi.getSelectedRows(),
						},
					}),
			});
		}
	}

	_firstDataRendered() {
		if (typeof this.gridApi.getFilterInstance('enabled') === 'object') {
			if (!this.gridApi.getFilterInstance('enabled').appliedModel) {
				this.gridApi.setFilterModel({
					enabled: {
						filterType: 'set',
						values: ['true'],
					},
				});
				this.gridApi.onFilterChanged();
			}
		}

		this._resizeColumns();
		this.setState({ gridReady: true });
	}

	_resizeColumns() {
		const columnIds = ['checkbox', 'actions', 'showlock'];
		this.gridColumnApi.autoSizeColumns(columnIds, false);

		this.gridApi.sizeColumnsToFit();
		if (this.props.adjustHeightToContent) this.gridApi.setDomLayout('autoHeight');
	}

	/**
	 * get reference list
	 * @returns
	 */
	async getReferenceList() {
		const referenceListConsumerProps = {
			getReferenceListValues: this.props.getReferenceListValues,
			setReferenceListValues: this.props.setReferenceListValues,
			isReferenceListLoaded: this.props.isReferenceListLoaded,
		};
		let data = await contextOrAPIGetReference(this.props.recordType, referenceListConsumerProps, true);
		if (this.props.configList.relatedReferences) {
			let relatedRefsData = await getRelatedReferencesData(
				this.props.configList.relatedReferences,
				referenceListConsumerProps,
				true
			);
			data = await matchReferenceList(data, relatedRefsData, this.props.configList.keysMatching);
		}
		return data;
	}

	async _retrieveData() {
		const { recordType } = this.props;
		let updateData = [];
		// TODO: refactor
		if (recordType === 'workflow') {
			updateData = await getSplittedWorkflow();
		} else if (this.props.permissionRecord === 'reference') {
			updateData = await this.getReferenceList();
		} else if (!this.props.useSearchUrl) {
			if (this.props.configList.hasShared) {
				if (this.props.configList.sharing) {
					let data = [];
					try {
						const response = await this.props.dataService.getAll();
						data = response.data.result ? response.data.result : response.data;

						const formatedData = [];
						let groups = await this.getRecordType('group');

						groups = groups[0] || [];
						groups = groups.map((c) => {
							c.code = c.name;
							return c;
						});

						if (Array.isArray(data.settings)) {
							data.settings.map((s) => {
								if (s.source) {
									formatedData.push({
										id: s.id,
										sender: s.source,
										code: s.code,
										name: s.name,
									});
								} else if (Array.isArray(s.groups)) {
									s.groups.map((c) => {
										formatedData.push({
											id: s.object_id,
											type: s.type,
											code: s.resource_code,
											name: s.resource_name,
											created_by_user_id: s.created_by_user_id,
											created_by_email: s.created_by_email,
											created_at: s.created_at,
											end_at: c.end_at,
											start_at: c.start_at,
											group_id: c.group_id,
											group: groups.filter((x) => x.id === c.group_id),
										});
										return c;
									});
								}

								return s;
							});

							data = formatedData;
						}
					} catch (err) {
						console.log('err retrieving data', err);
					}

					updateData = data;
				} else {
					const response = await Promise.all([
						...(this.props.configList.splitLaunch
							? this.getSplitResult()
							: [this.props.dataService.getAllWithParamObj()]),
						...[
							// dataset api already has the sharing data, old and new
							// TODO: once all sharing is done through contract, remove this API
							recordType !== 'dataset'
								? new DataService({
										url: `${baseUrl}data_sharing`,
										token: true,
										urlParams: `?relation=[${recordType}]`,
									}).getAll()
								: null,
							recordType === 'trade_flow'
								? new DataService({
										url: `${baseUrl}trade_flow_snapshot`,
										urlParams: '?deep=true',
									}).getAll()
								: null,
						].filter(Boolean),
					]);
					const datas = [];

					for (let i = 0; i < response.length; i++) {
						const el = response[i];
						if (!el.config) continue;
						if (el.config.baseURL.includes('/trade_flow_snapshot')) continue;
						if (el.config.baseURL.includes('/data_sharing')) {
							el.data.map((x) => {
								if (x[recordType]) {
									x[recordType].shared = true;
									x[recordType].shared_id = x.id;
									if (!datas.some((e) => e.id === x[recordType].id)) {
										datas.push(x[recordType]);
									}
								}
							});
						} else {
							const dataRes =
								el.config.baseURL.includes(`/dashboards`) ||
								el.config.baseURL.includes(`/${data}/`) ||
								el.config.baseURL.includes(`/${fundamentals}`) // TODO: Dynamize this exception
									? (el.data.result || []).map((el, index) => {
											return {
												key: index,
												...el,
											};
										})
									: el.data;

							dataRes.map((x) => {
								x.shared = x.shared || false;
								datas.push(x);
							});
						}
					}

					const updatedData = datas.flat();

					if (this.props.configList.filteredByType) {
						updateData = updatedData.filter((x) => `${x.type}s` === this.props.match?.params?.type);
					} else {
						updateData = updatedData;
					}
				}
			} else {
				let data = [];
				if (this.props.configList.splitLaunch) {
					const prom = await Promise.all(this.getSplitResult());
					for (let i = 0; i < prom.length; i++) {
						data = [...prom[i].data, ...data];
					}
				} else {
					const response = await this.props.dataService.getAll();
					data = response.data.result ? response.data.result : response.data;
				}

				updateData = data;
			}
		} else {
			let data = [];
			const response = await Promise.all([
				...(this.props.configList.splitLaunch
					? this.getSplitResult(true)
					: [this.props.dataService.getAlls(this.props.paramSearch)]),
			]);

			for (let i = 0; i < response.length; i++) {
				data.push(Array.isArray(response[i].data) ? response[i].data : []);
			}

			data = data.flat();

			updateData = data;
		}
		this._updateData(updateData);
		this.gridApi?.hideOverlay();
	}

	getSplitResult(isSearch = false) {
		const split = parseInt(this.props.configList.split) || 5000;
		//TODO: This condition on email should be removed after migrating email APIs to be able to
		//test on the total size of data and run the API enough times to display all existing data.
		const numberApiCalls = this.props.recordType === 'email' ? 4 : 3;
		const s = [];
		for (let i = 1; i <= numberApiCalls; i++) {
			const url = this.props.configList.useSearchUrl
				? searchUrl + this.props.configList.paramUrl
				: this.props.configList.url;

			const val = new DataService({
				url,
				urlParams: `?limit=${split}&offset=${(i - 1) * split}${this.props.urlParameters}`,
			});

			if (isSearch) {
				s.push(val.getAlls(this.props.paramSearch));
			} else {
				s.push(val.getAll());
			}
		}
		return s;
	}

	_onFilterTextBoxChanged(e) {
		this.setState({ filterTextBox: e.target.value });
		this._resetSearchFilter();
		this.gridApi.setQuickFilter(e.target.value);
	}

	_onSelectFilterChange(value, type) {
		this.setState(
			{
				[`select${type}Value`]: value,
			},
			function () {
				this.gridApi.onFilterChanged();
			}
		);
	}

	isExternalFilterPresent() {
		const selectedValues = this.state.canFilter.filter((el) => this.state[`select${el}Value`]?.length);
		if (selectedValues.length > 0) {
			return true;
		}
		return false;
	}

	doesExternalFilterPass(node) {
		let filterNumber = 0;
		let foundItemCount = 0;
		this.state.canFilter.filter((el) => {
			if (this.state[`select${el}Value`]) filterNumber++;
			if (this.state[`select${el}Value`] && node.data[el]?.name === this.state[`select${el}Value`]) {
				foundItemCount++;
			}
		});

		if (filterNumber > 0 && filterNumber === foundItemCount) {
			return true;
		}
	}

	_resetSearchFilter() {
		this.gridColumnApi.getAllColumns().forEach((column) => {
			column.getColDef().getQuickFilterText = undefined;
		});
		this.gridApi.onFilterChanged();
	}

	/**
	 * delete Record (with multiple API)
	 * @param {*} id
	 * @returns
	 */
	async deleteRecord(id) {
		if (this.props.permissionRecord === 'reference') {
			const currConfig = config.records[this.props.recordType];
			if (currConfig && currConfig.referenceRecord) {
				this.setState({ isLoadingOverlay: true });
				return deleteReferenceByCode(currConfig.referenceRecord, id).finally((res) => {
					this.setState({ isLoadingOverlay: false });
				});
			}
		}

		let requestService = this.props.dataService;
		requestService.urlParams = '';
		if (
			this.props.dataService.http.defaults.baseURL.includes('search') ||
			this.props.recordType === 'category' ||
			this.props.recordType === 'dataset'
		) {
			requestService = new DataService({
				url: this.props.configList.url,
				urlParams: '',
			});
		}
		return await requestService.delete(id);
	}

	/**
	 *
	 * @param {*} id
	 * @returns
	 */
	async bulkDeleteRecord(codes) {
		if (this.props.permissionRecord === 'reference') {
			const currConfig = config.records[this.props.recordType];
			if (currConfig && currConfig.referenceRecord) {
				this.setState({ isLoadingOverlay: true });
				return bulkDeleteReferencesService(currConfig.referenceRecord, { codes });
			}
		} else if (this.props.recordType === 'category') {
			return bulkDeleteCategoryService({ codes });
		} else if (this.props.recordType === 'dataset') {
			return bulkDeleteDatasetService({ codes });
		}
	}

	deleteOperation = (params) => {
		this.deleteRecord(
			this.props.recordType === 'category' ||
				this.props.recordType === 'dataset' ||
				this.props.permissionRecord === 'reference'
				? params.data.code
				: params.data.id
		)
			.then(() => {
				pushNotification(this.props.addToast, 'Selected record has been successfully deleted.', 'success');
				// We update the table, only if the deletion was successful
				this.gridApi.applyTransaction({
					remove: [params.data],
				});
				// Refresh context after delete one ref
				if (this.props.permissionRecord === 'reference') {
					refreshContextListReference(
						this.props.recordType,
						this.state.rowData,
						{ code: params.data.code },
						this.props,
						'delete'
					);
				}
			})
			.catch((error) => {
				let message = 'A problem occured while deleting records. Please try again later';
				const bodyMessage = error.response.data;
				if (bodyMessage.message) message = bodyMessage.message;
				if (bodyMessage.object) {
					message = (
						<>
							<p>{message}</p>
							<ul>
								{bodyMessage.object.map((el) => (
									<li>{el.name ? el.name : `${el.obj}(${el.nb})`}</li>
								))}
							</ul>
						</>
					);
				}
				pushNotification(this.props.addToast, message, 'error');
			});
	};

	_deleteOneRecord(params) {
		if (this.props.recordType === 'report' && !params.data.is_open) {
			closeNotebook(params.data.id);
			setTimeout(() => {
				this.deleteOperation(params);
			}, 1000);
		} else {
			this.deleteOperation(params);
		}
	}

	_deleteSelectedRecords() {
		const selectedRows = this.gridApi.getSelectedRows();
		try {
			// bulk delete (dataset | category | reference)
			if (
				this.props.recordType === 'dataset' ||
				this.props.recordType === 'category' ||
				this.props.permissionRecord === 'reference'
			) {
				let selectedCodes = selectedRows.map((obj) => obj.code);
				this.bulkDeleteRecord(selectedCodes)
					.then(
						() => {
							pushNotification(
								this.props.addToast,
								'Selected records have been successfully deleted.',
								'success'
							);
							this.gridApi.applyTransaction({
								remove: selectedRows,
							});
							// Refresh context after bulk delete
							if (this.props.permissionRecord === 'reference') {
								selectedCodes.forEach((code) => {
									refreshContextListReference(
										this.props.recordType,
										this.state.rowData,
										{ code },
										this.props,
										'delete'
									);
								});
							}
						},
						(err) => {
							pushNotification(
								this.props.addToast,
								err?.response?.data?.message || 'An error occured when deleting records.',
								'error'
							);
						}
					)
					.finally(() => {
						this.setState({ isLoadingOverlay: false });
					});
			}
			//TODO: to be changed with bulkDelete
			else {
				Promise.all(selectedRows.map((row) => this.deleteRecord(row.id))).then(() => {
					pushNotification(
						this.props.addToast,
						'Selected records have been successfully deleted.',
						'success'
					);
					// We update the table, only if the deletion was successful
					this.gridApi.applyTransaction({
						remove: selectedRows,
					});
				});
			}
		} catch (error) {
			let message = 'A problem occured while deleting records. Please try again later';

			let bodyMessage = error.response.data.object;
			if (error.response.data?.object?.message === undefined) bodyMessage = error.response.data;

			if (bodyMessage.message) message = bodyMessage.message;
			if (bodyMessage.object) {
				message = (
					<>
						<p>{message}</p>
						<ul>
							{bodyMessage.object.map((el) => (
								<li>{el.name ? el.name : `${el.obj}(${el.nb})`}</li>
							))}
						</ul>
					</>
				);
			}
			pushNotification(this.props.addToast, message, 'error');
		}
	}

	_saveState() {
		if (!this.state.gridReady) return;

		localStorage.setItem(
			`${this.props.recordType}_list_state`,
			JSON.stringify({
				colState: this.gridColumnApi.getColumnState(),
				groupState: this.gridColumnApi.getColumnGroupState(),
				sortState: this.gridApi.getSortModel(),
				filterState: this.gridApi.getFilterModel(),
			})
		);
	}

	_restoreState() {
		this.recordListState = localStorage.getItem(`${this.props.recordType}_list_state`);
		if (!this.recordListState) {
			if (this.props.defaultSortModel) this.gridApi.setSortModel(this.props.defaultSortModel);
			return;
		}

		this.recordListState = JSON.parse(this.recordListState);

		const { colState, groupState, sortState, filterState } = this.recordListState;

		if (colState[0].colId === 'checkbox') {
			colState[0].hide = false;
			colState[0].pinned = 'left';
		}

		this.gridColumnApi.setColumnState(colState);
		this.gridColumnApi.setColumnGroupState(groupState);
		this.gridApi.setSortModel(sortState);
		this.gridApi.setFilterModel(filterState);
	}

	_getContextMenuItems(params) {
		const self = this;
		let filterInstance;
		let filterType;
		let type;
		let model;
		const result = ['copy', 'copyWithHeaders', 'separator', 'export'];

		return result;
	}

	openEditRecord(params) {
		const currentstep = params.data.last_step ? params.data.last_step : '';

		let id = '';
		if (this.props.navigateByCode) {
			id = params ? params.data.code + '/edit' : 'new';
		} else {
			id = params ? params.data.id + '/edit' : 'new';
		}

		id += currentstep === '' ? '' : `/${currentstep}`;

		if (this.pressed) {
			window.open(`${this.props.path}/${id}${this.props.useTab ? `/${this.props.useTab}` : ''}`, '_blank');
			return;
		}

		if (this.props.configList.filteredByType) {
			this.props.history.push({
				pathname: `${this.props.location.pathname}/${id}${this.props.useTab ? `/${this.props.useTab}` : ''}`,
				state: this.props.history?.location?.state || null,
			});
			return;
		}
		this.props.history.push({
			pathname: `${this.props.path}/${id}`,
			state: this.props.history?.location?.state || null,
		});
	}

	openCloneRecord(params) {
		let id = '';

		if (this.props.navigateByCode) {
			id = params ? params.data.code + '/clone' : 'new';
		} else {
			id = params ? params.data.id + '/clone' : 'new';
		}

		if (this.pressed) {
			window.open(`${this.props.path}/${id}${this.props.useTab ? `/${this.props.useTab}` : ''}`, '_blank');
			return;
		}

		this.props.history.push(`${this.props.path}/${id}`);
	}

	async listCodes() {
		return await new DataService({
			url: this.props.configList.url,
			urlParams: '?limit=1000',
		}).getAll();
	}

	async getDeepRecord(id) {
		return await new DataService({
			url: this.props.configList.url,
			urlParams: '?deep=true',
		}).get(id);
	}

	duplicateRecord(params) {
		const id = params ? params.data.id : null;

		if (!id) return;

		const btns = [
			{
				input: (
					<Input
						placeholder="New code"
						type="textarea"
						defaultValue={
							params.data.code ? `${params.data.code.replace(`${this.org}-`, '')}-duplicated` : ''
						}
						addonBefore={`${this.org || 'empty'}-`}
					/>
				),
				name: 'code',
				label: 'Code',
				rules: [
					{ required: true },
					{
						validator: (_, value) => {
							if (!Array.isArray(listCodes)) return Promise.resolve();
							value = `${this.org}-${value}`;
							const keys = listCodes.filter((el) => el.code.toLowerCase() === value.toLowerCase()) || [];

							return keys.length > 0
								? Promise.reject(new Error('This code has been already used'))
								: Promise.resolve();
						},
					},
				],
			},
		];
		let listCodes = [];

		if (this.props.pushModal)
			return this.props.pushModal(
				<PromptModal
					title="Define name of duplicate element"
					label="Name"
					value={params.data.name ? params.data.name : ''}
					onConfirm={(formValues) => {
						const code = formValues.code || `${params.data.code}-duplicated`;
						const name = formValues.value || `${params.data.name}-duplicated`;
						const keys = listCodes.filter((el) => el.code.toLowerCase() === code.toLowerCase()) || [];

						if (keys.length > 0) {
							pushNotification(this.props.addToast, 'This code is currently used', 'error');
						} else {
							this.gridApi.showLoadingOverlay();
							pushNotification(this.props.addToast, 'Resource duplication processing', 'info');

							new DataService({
								url: config.api.duplicateUrl,
								urlParams: !params.data.shared
									? '?deep=true'
									: `?relation=[${this.props.configList.recordid.replace('_id', '')}]`,
							})
								.create({
									ressource: this.props.configList.recordid.replace('_id', ''),
									recordId: params.data.shared ? params.data.shared_id : id,
									shared: !!params.data.shared,
									name,
									code: `${this.org}-${code}`,
								})
								.then((result) => {
									const innerDatas = result.data.datas;
									const mappedEntries = result.data.mappedEntries || {};

									if (this.props.recordType === 'trade_flow') {
										this.listMappedEntries(mappedEntries, params);
									}
									if (!this.props.configList.askCode) {
										delete innerDatas.code;
									}

									if (innerDatas.workflow !== undefined) delete innerDatas.workflow;

									new DataService({
										url: this.props.configList.url,
										urlParams: this.props.configList.duplicateRelation
											? '?relation=[email_ruleset_rule]'
											: '?deep=true',
									})
										.create(innerDatas)
										.then((result) => {
											pushNotification(
												this.props.addToast,
												'Selected records have been successfully duplicated.',
												'success'
											);
											this.refreshCells();
											this.gridApi.hideOverlay();
										})
										.catch((error) => {
											if (error.response?.data?.object) {
												if (
													error.response.data.object.name === 'SequelizeUniqueConstraintError'
												) {
													pushNotification(
														this.props.addToast,
														'This code has already been used',
														'error'
													);
													return;
												}
											}
											pushNotification(
												this.props.addToast,
												'An error occured while duplicated.',
												'error'
											);
											this.gridApi.hideOverlay();
										});
								})
								.catch((error) => {
									console.log(error);
								});
						}
					}}
				>
					{this.props.configList.askCode ? btns : null}
				</PromptModal>
			);

		return null;
	}

	listMappedEntries(mappedEntries, params) {
		if (Object.keys(mappedEntries)?.length > 0 && params.data.shared) {
			const message = (
				<>
					<h5>Mapped values</h5>
					{Object.keys(mappedEntries).map((me) => (
						<>
							<b>{me}</b>
							<ul>
								{mappedEntries[me].map((en) => (
									<li>
										{' '}
										{en.code} <FontAwesomeIcon icon={en.newId ? faCheck : faTimes} />
									</li>
								))}
							</ul>
						</>
					))}
				</>
			);

			pushNotification(this.props.addToast, message, 'info', '', false);
		}
	}

	openSettingsRecord(params) {
		const id = params ? `${params.data.id}/settings` : '';

		if (this.pressed) {
			window.open(`${this.props.path}/${id}${this.props.useTab ? `/${this.props.useTab}` : ''}`, '_blank');
			return;
		}

		this.props.history.push(`${this.props.path}/${id}`);
	}
	openStatsRecord(params) {
		const id = params ? params.data.id + '/stats' : '';

		if (this.pressed) {
			window.open(this.props.path + '/' + id + (this.props.useTab ? '/' + this.props.useTab : ''), '_blank');
			return;
		}

		this.props.history.push(this.props.path + '/' + id);
	}
	async executeWorkflow(params) {
		const dataService = new DataService({ urlParams: [] });
		const requestService = dataService.getRequestService();
		const { currentExecution } = this.state;
		currentExecution.push(params.data.id);
		this.setState({ currentExecution });
		this._alterRowData(params.data.id, { last_status: 'waiting', last_step: 'loading' });

		// Execute loading Step
		scheduleWorkflowImmediately(params.data.trigger_type, params.data.id)
			.then((response) => {
				console.log(response);
			})
			.catch((err) => {
				console.error('Error from ', err);
			});

		return false;
	}

	async executeDataPrep(params) {
		const { currentExecution } = this.state;
		currentExecution.push(params.data.code);
		this.setState({ currentExecution });

		this._alterRowData(params.data.code, { last_status: 'waiting' }, null, 'code');

		try {
			await run({ code: params.data.code });
		} catch (err) {
			pushNotification(this.props.addToast, this.props.t(`modules.pipeline.messages.error.run`), event.type);
			this._alterRowData(params.data.code, { last_status: 'error' }, null, 'code');
		}

		return false;
	}

	async executeFreightRate(params) {
		const dataService = new DataService({
			url: `https://api.dnext.io/freight/schedule-immediately/${params.data.id}`,
			// url: `${baseApiUrl}freight/schedule-immediately/${response?.data?.object?.id}`,
			token: true,
			urlParams: '',
		});
		// let requestService = dataService.getRequestService();
		const { currentExecution } = this.state;
		currentExecution.push(params.data.id);
		this.setState({ currentExecution });
		this._alterRowData(params.data.id, { last_status: 'waiting', last_step: 'loading' });

		dataService
			.create()
			.then((response) => {
				pushNotification(this.props.addToast, 'Calculation successfully completed.', 'success');
			})
			.catch((err) => {
				pushNotification(
					this.props.addToast,
					'Calculation went wrong, please check your data and try again',
					'error'
				);
			});
		return false;
	}

	async executeLineupConsolidation(params) {
		const dataService = new DataService({
			url: `https://api.dnext.io/lineup/schedule-immediately/${params.data.id}`,
			token: true,
			urlParams: '',
		});
		// let requestService = dataService.getRequestService();
		const { currentExecution } = this.state;
		currentExecution.push(params.data.id);
		this.setState({ currentExecution });
		this._alterRowData(params.data.id, { last_status: 'waiting', last_step: 'loading' });

		dataService
			.create()
			.then((response) => {
				pushNotification(this.props.addToast, 'Calculation successfully completed.', 'success');
			})
			.catch((err) => {
				pushNotification(
					this.props.addToast,
					'Calculation went wrong, please check your data and try again',
					'error'
				);
			});
		return false;
	}

	refreshAndViewRecord(params) {
		const closeParam = params.data.shared ? '?type=shared&a=refresh' : '?a=refresh';
		const id = params ? (params.data.shared ? params.data.shared_id : params.data.code) : 'new';

		this.props.history.push({
			pathname: `${this.props.path}/${id}${this.props.useTab ? `/${this.props.useTab}` : ''}${
				params.data.aggregated ? '/aggregation' : ''
			}`,
			state: this.props.history?.location?.state || null,
			search: closeParam,
		});
	}

	refreshAndDownload(params) {
		this.popConfirmDownload(params, false);
	}

	async popConfirmDownload(params, cache = true) {
		const btns = [
			{
				input: <Switch defaultValue={false} />,
				name: 'includeIntraflow',
				label: this.props.t('pages.tradeflow.intraflows_download'),
			},
		];

		const shared = !!params.data.shared;

		if (!shared) {
			btns.push({
				input: <Switch defaultValue={false} />,
				name: 'includeROW',
				label: this.props.t('pages.tradeflow.row'),
			});
		}

		return this.props.pushModal(
			<PromptModal
				title={this.props.t('pages.tradeflow.download_with')}
				onConfirm={(formValues) => {
					this.downloadRecord(params, cache, formValues);
				}}
				onlyChildren
			>
				{btns}
			</PromptModal>
		);
	}
	async downloadRecord(params, cache = true, formValues) {
		this.setState({ downloading: true }, () => {
			this.gridApi.showLoadingOverlay();
		});

		switch (this.props.recordType) {
			case 'trade_flow':
				let result = await downloadTradeflow(
					params.data.code,
					cache,
					!formValues.includeIntraflow,
					formValues.includeRow,
					'csv',
					{},
					params.data.aggregated
				);

				if (!result)
					pushNotification(
						this.props.addToast,
						'A problem has occurred while trying to download the tradeflow. Please check the configuration and make sure you have access to it.',
						'error'
					);

				break;
			default:
				break;
		}
		this.gridApi.hideOverlay();
	}

	openViewRecord(params) {
		let id = 'new';
		let closeParam = '';
		if (params) {
			// for compatility of reference that are using / and #. Those are replace with ( and ) because of the url encoding that has to work for serverless and react router.
			id = this.props.navigateByCode ? params.data.code.replace('/', '%28').replace('#', '%29') : params.data.id;
			if (this.props.configList.hasShared) {
				if (params.data.shared && params.data.shared_id) {
					id = params.data.shared_id;
					closeParam = '?type=shared';
				}
			}
		}

		if (this.props.configList.filteredByType) {
			this.props.history.push({
				pathname: `${this.props.location.pathname}/${id}${this.props.useTab ? `/${this.props.useTab}` : ''}${
					params.data.aggregated ? '/aggregation' : ''
				}`,
				state: this.props.history?.location?.state || null,
				search: closeParam,
			});
			return;
		}

		if (this.pressed) {
			window.open(
				`${this.props.path}/${id}${this.props.useTab ? `/${this.props.useTab}` : ''}${closeParam}`,
				'_blank'
			);
			return;
		}

		this.props.history.push({
			pathname: `${this.props.path}/${id}${this.props.useTab ? `/${this.props.useTab}` : ''}${
				params.data.aggregated ? '/aggregation' : ''
			}`,

			state: this.props.history?.location?.state || null,
			search: closeParam,
		});
	}

	getSelectedRecords() {
		return this.gridApi.getSelectedRows();
	}

	moveParentSelectedRecords(state) {
		const selectedRows = this.gridApi.getSelectedRows();

		Promise.all(selectedRows.map((row) => this.moveParentSelectedRecord(row, state)))
			.then(() => {
				pushNotification(this.props.addToast, 'Selected records have been successfully updated.', 'success');
				// We update the table, only if the deletion was successful
				this._retrieveData();
			})
			.catch((error) => {
				const message = 'A problem occured while updating records. Please try again later';
				pushNotification(this.props.addToast, message, 'error');
			});
	}

	toggleActiveRecords(state) {
		let selectedRows = this.gridApi.getSelectedRows();

		selectedRows = selectedRows.map((row) => {
			row.is_archived = state === 'true';
			return row;
		});

		Promise.all(selectedRows.map((row) => this.toggleActiveRecord(row, state)))
			.then(() => {
				pushNotification(this.props.addToast, 'Selected records have been successfully updated.', 'success');
				// We update the table, only if the deletion was successful
				this.gridApi.applyTransaction({
					update: selectedRows,
				});
			})
			.catch((error) => {
				let message = 'A problem occured while updating records. Please try again later';
				const bodyMessage = error.response.data;
				if (bodyMessage.message) message = bodyMessage.message;
				if (Array.isArray(bodyMessage.object)) {
					message = (
						<>
							<p>{message}</p>
							<ul style={{ fontSize: '13px' }}>
								{bodyMessage.object.map((el) => (
									<li>{el.name ? el.name : `${el.obj}(${el.nb})`}</li>
								))}
							</ul>
						</>
					);
				} else if (bodyMessage?.object?.message) {
					message = bodyMessage.object.message;
				}
				pushNotification(this.props.addToast, message, 'error');
			});
	}

	async toggleStateWorkflows(state) {
		const selectedRows = this.gridApi.getSelectedRows();

		await Promise.all(selectedRows.map((row) => this.toggleStateWorkflow(row.id, state)))
			.then(() => {
				pushNotification(this.props.addToast, 'Selected records have been successfully updated.', 'success');
				this.gridApi.applyTransaction({
					update: selectedRows,
				});
				this.refreshCells();
			})
			.catch((error) => {
				console.log(error);
			});

		return 'done';
	}

	// Apply tags to resources by type
	async ApplyTagsEntity(dataToSend) {
		return applyTagsByResources(dataToSend).then(
			(res) => {
				this.refreshCells();
				pushNotification(this.props.addToast, this.props.t('model.label.success.applied'), 'success');
			},
			(err) => {
				pushNotification(this.props.addToast, this.props.t('model.label.errors.not_applied'), 'error');
			}
		);
	}

	async toggleStateWorkflow(id, state) {
		return await new DataService({
			url: config.records.workflow.url,
			urlParams: '',
			token: true,
		}).update(id, {
			state,
		});
	}

	async moveParentSelectedRecord(row, state) {
		// TODO: to be changed and cleaned after release
		if (this.props.permissionRecord === 'reference') {
			const currConfig = config.records[this.props.recordType];
			if (currConfig && currConfig.referenceRecord) {
				this.setState({ isLoadingOverlay: true });
				const payload = {
					id: row.id,
					code: row.code,
					name: row.name,
					[this.props.parentField]: state && state !== 'none' ? state : null,
				};
				return updateReference(currConfig.referenceRecord, row.code, payload).finally((res) => {
					this.setState({ isLoadingOverlay: false });
				});
			}
		}
		return await this.props.dataService.update(row.id, {
			[this.props.parentField]: state && state !== 'none' ? state : null,
		});
	}

	async toggleActiveRecord(row, state) {
		// TODO: to be changed and cleaned after release
		if (this.props.permissionRecord === 'reference') {
			const currConfig = config.records[this.props.recordType];
			if (currConfig && currConfig.referenceRecord) {
				this.setState({ isLoadingOverlay: true });
				const payload = { id: row.id, code: row.code, name: row.name, is_archived: state === 'true' };
				return updateReference(currConfig.referenceRecord, row.code, payload).finally((res) => {
					this.setState({ isLoadingOverlay: false });
				});
			}
		}
		return await this.props.dataService.update(row.id, { is_archived: state === 'true' });
	}

	onConfirmDialogClick(choice) {
		this.setState({ isModalOpen: false });
		if (choice) this.state.confirmProps.confirmFunction();
	}

	onOpenDeleteUserModal = (params) => {
		const users = params ? [params.data] : this.gridApi.getSelectedRows();
		this.setState({ isOpenDeleteModal: true, selectedUsers: users });
	};
	askConfirmationBeforeDelete(deleteFunction, params) {
		let message;

		// Single deletion from action
		if (params) {
			message = 'Delete this record?';
		}
		// Multiple deletion from checkbox selection
		else {
			const numberOfRecords = this.gridApi.getSelectedRows().length;

			if (numberOfRecords > 1) message = `Delete selected (${numberOfRecords}) records?`;
			else message = 'Delete selected record?';
		}

		this.setState({
			isModalOpen: true,
			confirmProps: {
				confirmFunction: () => deleteFunction(params),
				confirmtext: message,
			},
		});
	}

	refreshCells() {
		this.setState({ filterTextBox: '' });
		this.gridApi?.showLoadingOverlay();
		this.state.canFilter.map((el) => {
			this.setState({ [`select${el}Value`]: null }, function () {
				this.gridApi?.onFilterChanged();
			});
		});
		this._restoreState();
		this.gridApi?.setQuickFilter('');
		if (this.props.permissionRecord === 'reference') {
			const currConfig = config.records[this.props.recordType];
			if (!currConfig) return;
			getReferenceDataByType(currConfig)
				.then((res) => {
					this._updateData(res || []);
				})
				.finally(() => {
					this.gridApi?.hideOverlay();
				});
		} else if (this.props.dataService) this._retrieveData();
	}

	clearFilters() {
		this.setState({ filterTextBox: null });
		this.state.canFilter.map((el) => {
			this.setState({ [`select${el}Value`]: null }, function () {
				this.gridApi.setFilterModel({});
			});
		});
		this._onFilterTextBoxChanged({ target: { value: null } });
		this.gridApi.setFilterModel({});
	}

	hasFilters() {
		if (!this.gridApi) return false;
		return this.state.filterTextBox || !isEmpty(this.gridApi.getFilterModel());
	}

	render() {
		if (!this.state.ready) {
			return <Loader />;
		}
		const rowData = this.state.rowData || [];
		let searchBoxControl;
		let paginationSizeControl;
		let refreshCells;
		let clearFilters;
		let selectFilters;
		let selectColumns;

		if (this.props.showSearchBox) {
			searchBoxControl = (
				<Input
					addonAfter={<SearchOutlined />}
					id="filter-text-box"
					value={this.state.filterTextBox || null}
					placeholder={this.props.t('model.list.search')}
					onChange={(e) => this._onFilterTextBoxChanged(e)}
					style={{ width: 300 }}
					className="mr-1"
				/>
			);
		}
		refreshCells = (
			<Tooltip title={this.props.t('model.list.refresh_list')} placement="topLeft">
				<Button onClick={() => this.refreshCells()} className="mr-1">
					<FontAwesomeIcon icon={faSyncAlt} />
				</Button>
			</Tooltip>
		);
		clearFilters = (
			<Tooltip title={this.props.t('model.list.clean_filters')} placement="topLeft">
				<Button
					onClick={() => this.clearFilters()}
					className={`mr-1 ${this.hasFilters() ? 'ant-btn-danger' : ''}`}
				>
					<span className="fa-layers fa-fw">
						<FontAwesomeIcon icon={faFilter} transform="up-2 left-3" />
						<FontAwesomeIcon icon={faTimesCircle} transform="shrink-4 down-6 right-6" />
					</span>
				</Button>
			</Tooltip>
		);

		selectFilters = (
			<>
				{this.state.canFilter.map((el) => {
					const options = _.uniqBy(rowData, `${el}.name`)
						.filter((item) => item[el])
						.map((option) => {
							return {
								id: option[el].id,
								name: option[el].name,
							};
						});
					const sortedOptions = _.sortBy(options, (item) => {
						return item?.name?.toLowerCase();
					});

					return (
						<Select
							value={this.state[`select${el}Value`] || null}
							style={{ width: 180 }}
							className="mr-1"
							onChange={(value) => this._onSelectFilterChange(value, el)}
							allowClear
							showSearch
							filterOption={(input, option) =>
								option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
							key={`search${el}`}
							placeholder={`${this.props.t('model.list.search_by') + el}...`}
						>
							{sortedOptions.map((s) => {
								return (
									<Option key={s.id} value={s.name}>
										{s.name}
									</Option>
								);
							})}
						</Select>
					);
				})}
			</>
		);

		selectColumns = this.props.renderSelectorMenu !== false &&
			this.gridColumnApi?.getAllGridColumns()?.length > 0 && (
				<CustomComponents.RenderColumnPopOver
					cols={
						this.state.getAllGridColumns
							? this.state.getAllGridColumns
							: this.gridColumnApi
								? this.gridColumnApi.getAllGridColumns()
								: []
					}
					toggleVisibleColumn={(col, visible, all) => {
						if (!all) this.gridColumnApi.setColumnVisible(col, visible !== true);
						else this.gridColumnApi.setColumnsVisible(col, visible !== true);
						this.setState({
							getAllGridColumns: this.gridColumnApi.getAllGridColumns(),
							axKey: this.state.axKey + 1,
						});
					}}
					axKey={this.state.axKey}
				/>
			);
		if (this.props.showPagination && this.props.showRowsPerPageControl) {
			paginationSizeControl = (
				<FloatLabel label={this.props.t('model.list.rows_per_page')} name="page-size" className="float-right">
					<Select
						name="page-size"
						id="page-size"
						style={{ width: 70 }}
						onChange={(value) => this.gridApi.paginationSetPageSize(value)}
						defaultValue={this.props.rowsPerPage}
					>
						<Option value="10">10</Option>
						<Option value="25">25</Option>
						<Option value="50">50</Option>
						<Option value="100">100</Option>
					</Select>
				</FloatLabel>
			);
		}

		const nbdata = Array.isArray(this.state.rowData) ? this.state.rowData.length : 0;

		return (
			<>
				{/* Loader */}
				<LoaderOverlay isLoading={this.state.isLoadingOverlay} />

				<div id="ag-grid-listing">
					<Row>
						<Col sm={12} className="mb-1  d-flex">
							{searchBoxControl} {selectFilters} {refreshCells} {clearFilters} {selectColumns}
							<div className="ml-auto d-flex" style={{ alignItems: 'end' }}>
								<span style={{ fontSize: '12px' }}>
									Total record(s): {this.state.filteredElement}/{nbdata}
								</span>
							</div>
						</Col>
						{paginationSizeControl ? <Col sm={8}>{paginationSizeControl}</Col> : null}
					</Row>
					<Row>
						<Col>
							<div
								className="ag-theme-balham" //className= "ag-theme-balham-dark"
								style={
									this.props.adjustHeightToContent
										? null
										: {
												height: 'calc(100vh - 120px)',
											}
								}
							>
								<AgGridReact
									isExternalFilterPresent={this.isExternalFilterPresent.bind(this)}
									doesExternalFilterPass={this.doesExternalFilterPass.bind(this)}
									// register all modules (row model, csv/excel, row grouping etc)
									treeData={this.state.isShowHierarchy}
									getDataPath={this.state.isShowHierarchy ? (data) => data.hierarchy : null}
									groupDefaultExpanded={this.state.isShowHierarchy ? -1 : null}
									onModelUpdated={() => {
										this.setState({
											filteredElement: this.gridApi?.getDisplayedRowCount() || nbdata,
										});
									}}
									rowHeight={35}
									modules={AllModules}
									columnDefs={this.state.columns}
									defaultColDef={this.state.defaultColDef}
									columnTypes={this.columnTypes}
									enableRangeSelection
									rowData={this.state.rowData}
									animateRows
									rowSelection={this.state.rowSelection}
									suppressRowClickSelection={this.state.suppressRowClickSelection}
									pagination={this.props.showPagination}
									paginationPageSize={this.props.rowsPerPage}
									rowGroupPanelShow={this.state.rowGroupPanelShow}
									autoGroupColumnDef={this.state.autoGroupColumnDef}
									groupSelectsChildren
									groupSelectsFiltered
									sideBar={false}
									masterDetail
									detailCellRenderer={this.state.detailCellRenderer}
									frameworkComponents={this.state.frameworkComponents}
									// onGridSizeChanged={this._resizeColumns.bind(this)}
									onFirstDataRendered={this._firstDataRendered.bind(this)}
									onGridReady={this._onGridReady.bind(this)}
									onDisplayedColumnsChanged={this._saveState.bind(this)}
									onFilterChanged={this._saveState.bind(this)}
									onSortChanged={this._saveState.bind(this)}
									deltaRowDataMode
									getRowNodeId={(data) => data.id}
									getContextMenuItems={this._getContextMenuItems.bind(this)}
									onSelectionChanged={
										this.props.onSelectionChanged ? this.props.onSelectionChanged.bind(this) : null
									}
									isRowSelectable={function (node) {
										return node.data ? !node.data.shared : true;
									}}
									debug={false}
								/>
							</div>
						</Col>
					</Row>
				</div>

				<DeleteUserModal
					refreshList={() => this.refreshCells()}
					users={this.state.selectedUsers}
					isOpen={this.state.isOpenDeleteModal}
					setIsOpen={(value) => this.setState({ isOpenDeleteModal: value })}
					setLoader={(value) => this.setState({ isLoadingOverlay: value })}
					referenceListConsumerProps={this.props.referenceListConsumerProps}
				/>

				<ConfirmDialog
					text="Confirmation"
					body={this.state.confirmProps.confirmtext}
					open={this.state.isModalOpen}
					onConfirmDialogClick={this.onConfirmDialogClick.bind(this)}
				/>

				{this.state.bulk_execution?.bulk_execution_model && this.state.bulk_execution?.records?.length ? (
					<BulkExecuteModal
						visible
						records={this.state.bulk_execution?.records || []}
						model={this.state.bulk_execution?.bulk_execution_model}
						onOk={() => this.setState({ bulk_execution: null })}
						onCancel={() => this.setState({ bulk_execution: null })}
					/>
				) : null}
			</>
		);
	}
}

export default List;
